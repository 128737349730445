import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cssVariables from '../../styles/variables';
import { getSearchUrl } from '../../utils/search/getSearchUrl';
import ClampText from '../shared/ClampText';
import SearchIcon from '../shared/svgIcons/SearchIcon';

import recentSearchesCss from './recent-searches.module.scss';
import css from './suggestion.module.scss';

export default function Suggestion({ suggestion, onClick }) {
  const searchTerm =
    suggestion?.actions?.search?.searchTerm || suggestion?.title;
  const searchIconColor = cssVariables['--secondary-color-5'];

  return (
    <Link
      data-testid={`search-suggestion-link-${suggestion?.index}`}
      to={getSearchUrl(searchTerm, suggestion?.context?.token)}
      className={recentSearchesCss.recentSearchLink}
      onClick={() => onClick(searchTerm)}
    >
      <div
        data-testid={`search-suggestion-item-${suggestion?.index}`}
        className={classNames(
          recentSearchesCss.recentSearchItem,
          css.suggestionItem,
        )}
      >
        <SearchIcon fill={searchIconColor} width="24px" height="24px" />
        <ClampText
          clamp={1}
          className={classNames(
            recentSearchesCss.recentSearchText,
            css.suggestionText,
          )}
        >
          {suggestion?.title}
        </ClampText>
      </div>
    </Link>
  );
}

Suggestion.propTypes = {
  suggestion: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};
