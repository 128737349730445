import classNames from 'clsx';
import PropTypes from 'prop-types';
import css from './title.module.scss';

export default function Title({
  pageTitle,
  pageTitleImage,
  children,
  description,
  guideId,
  overrideCss,
  isHidden,
}) {
  return (
    <div>
      {pageTitleImage ? (
        <img
          alt="page title"
          className={css.pageTitleImage}
          src={pageTitleImage}
          data-testid="pageTitleImage"
        />
      ) : (
        <h1
          className={classNames(css.pageTitle, {
            [css[overrideCss]]: overrideCss,
            [css.hasDescription]: description,
            srOnly: isHidden,
          })}
          data-testid="pageTitle"
          data-guideid={guideId}
        >
          {children || pageTitle}
        </h1>
      )}
      {description && (
        <p className={css.description} data-testid="pageDescription">
          {description}
        </p>
      )}
    </div>
  );
}

Title.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageTitleImage: PropTypes.string,
  children: PropTypes.string,
  description: PropTypes.string,
  guideId: PropTypes.string,
  overrideCss: PropTypes.string,
  isHidden: PropTypes.bool,
};
