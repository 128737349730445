import {
  searchResultsContentId,
  searchSuggestionsContentId,
} from '../actions/search';

const NAMESPACE = 'search';

export const selectSearchResults = (state) =>
  state?.[NAMESPACE]?.[searchResultsContentId];

export const selectSearchSuggestions = (state) =>
  state?.[NAMESPACE]?.[searchSuggestionsContentId];

export const selectHasSearchSuggestions = (state) =>
  !!state?.[NAMESPACE]?.[searchSuggestionsContentId]?.suggestions?.length ||
  false;
