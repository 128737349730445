import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logFeatureActivity } from '../../actions/logging';
import {
  addRecentSearch,
  clearRecentSearches,
  getRecentSearches,
  removeRecentSearch,
} from '../../actions/search';
import feature from '../../constants/analytics/categoryActionLabel/feature';
import {
  CLEAR_RECENT_SEARCHES_BUTTON,
  RECENT_SEARCHES_HEADER,
} from '../../constants/localizations/search';
import RecentSearchesContext from '../../contexts/RecentSearchesContext';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import hasRecentSearches from '../../utils/search/hasRecentSearches';
import RecentSearch from './RecentSearch';
import css from './recent-searches.module.scss';

class RecentSearches extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    recentSearches: PropTypes.array.isRequired,
    canShowRecentSearches: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      getRecentSearches: PropTypes.func.isRequired,
      addRecentSearch: PropTypes.func.isRequired,
      removeRecentSearch: PropTypes.func.isRequired,
      clearRecentSearches: PropTypes.func.isRequired,
      logFeatureActivity: PropTypes.func.isRequired,
    }),
    searchTerm: PropTypes.string,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);
    this.onResultsClick = this.onResultsClick.bind(this);
    this.clickRecentSearch = this.clickRecentSearch.bind(this);
    this.removeRecentSearch = this.removeRecentSearch.bind(this);
    this.clearRecentSearches = this.clearRecentSearches.bind(this);
  }

  componentDidMount() {
    this.props.actions.getRecentSearches();
  }

  onResultsClick() {
    const { actions, searchTerm } = this.props;
    if (searchTerm) {
      actions.addRecentSearch(searchTerm);
    }
  }

  logRecentSearchesActivity(label) {
    this.props.actions.logFeatureActivity(
      feature.actions.recentSearches,
      label,
    );
  }

  clickRecentSearch() {
    this.logRecentSearchesActivity(feature.labels.tap);
  }

  removeRecentSearch(termIndex) {
    this.props.actions.removeRecentSearch(termIndex);
    this.logRecentSearchesActivity(feature.labels.clearSingle);
  }

  clearRecentSearches(event) {
    event.preventDefault();
    this.props.actions.clearRecentSearches();
    this.logRecentSearchesActivity(feature.labels.clearAll);
  }

  render() {
    const { getLocalizedText } = this.context;
    const { children, recentSearches, canShowRecentSearches } = this.props;
    return (
      <>
        {canShowRecentSearches && (
          <div
            id="recent-searches-container"
            data-testid="recent-searches-container"
            className={css.recentSearchesContainer}
          >
            <div className={css.recentSearchesTitleContainer}>
              <h1
                data-testid="recent-searches-title"
                className={css.recentSearchesTitle}
              >
                {getLocalizedText(RECENT_SEARCHES_HEADER)}
              </h1>
              <div className={css.clearSearches}>
                <span
                  id="clear-recent-searches"
                  data-testid="clear-recent-searches"
                  onClick={this.clearRecentSearches}
                >
                  {getLocalizedText(CLEAR_RECENT_SEARCHES_BUTTON)}
                </span>
              </div>
            </div>
            {recentSearches.map((searchTerm, index) => (
              <RecentSearch
                key={`recent-search-${searchTerm}`}
                searchTerm={searchTerm}
                termIndex={index}
                onClick={this.clickRecentSearch}
                onRemove={this.removeRecentSearch}
              />
            ))}
          </div>
        )}
        <RecentSearchesContext.Provider value={this.onResultsClick}>
          {children}
        </RecentSearchesContext.Provider>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    search: { recentSearches, canShowRecentSearches },
  } = state;
  return {
    recentSearches,
    canShowRecentSearches: canShowRecentSearches && hasRecentSearches(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getRecentSearches,
        addRecentSearch,
        removeRecentSearch,
        clearRecentSearches,
        logFeatureActivity,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentSearches);
