import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import assetUrl from '../../utils/assetUrl';
import { getSearchUrl } from '../../utils/search/getSearchUrl';
import ClampText from '../shared/ClampText';

import css from './recent-searches.module.scss';

export default function RecentSearch({
  searchTerm,
  termIndex,
  onClick,
  onRemove,
}) {
  return (
    <Link
      data-testid={`recent-search-link-${termIndex}`}
      to={getSearchUrl(searchTerm)}
      className={css.recentSearchLink}
      onClick={onClick}
    >
      <div
        data-testid={`recent-search-item-${termIndex}`}
        className={classNames(css.recentSearchItem, {
          [css.isFirstSearchTerm]: termIndex === 0,
        })}
      >
        <ClampText clamp={1} className={css.recentSearchText}>
          {searchTerm}
        </ClampText>
        <img
          id={`remove-search-${termIndex}`}
          data-testid={`remove-search-${termIndex}`}
          className={css.removeRecentSearch}
          src={assetUrl('assets/img/shared/close-button-grey.svg')}
          alt="grey close button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onRemove(termIndex);
          }}
        />
      </div>
    </Link>
  );
}

RecentSearch.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  termIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
