import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cssVars from 'src/common/styles/variables';
import {
  SEARCHBAR_DISCORD_PLACEHOLDER_LARGE,
  SEARCHBAR_PLACEHOLDER_LARGE,
  SEARCHBAR_PLACEHOLDER_SMALL,
} from '../../constants/localizations/search';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectBreakpoint, selectIsDiscord } from '../../selectors/app';
import { selectHasSearchSuggestions } from '../../selectors/search';
import breakpoints from '../../styles/breakpoints';
import RecentSearches from './RecentSearches';
import SearchSuggestions from './SearchSuggestions';
import Searchbar from './Searchbar';
import css from './search.module.scss';

const hintStyle = {
  fontSize: '14px',
  lineHeight: '19px',
  color: cssVars['--cloud-grey'],
  fontFamily: cssVars['--primary-font'],
};

const iconProps = {
  width: cssVars['--medium-icon-size'],
  height: cssVars['--medium-icon-size'],
  fill: cssVars['--cloud-grey'],
};

const discordIconProps = {
  ...iconProps,
  fill: cssVars['--anti-flash-white'],
};

function getSearchbarPlaceholder(breakpoint, isDiscord) {
  const largePlaceholder = isDiscord
    ? SEARCHBAR_DISCORD_PLACEHOLDER_LARGE
    : SEARCHBAR_PLACEHOLDER_LARGE;

  return breakpoint <= breakpoints.XSMALL
    ? SEARCHBAR_PLACEHOLDER_SMALL
    : largePlaceholder;
}

export default function SearchContent({
  children,
  onChange,
  onSubmit,
  searchTerm,
}) {
  const breakpoint = useSelector(selectBreakpoint);
  const hasSearchSuggestions = useSelector(selectHasSearchSuggestions);
  const isDiscord = useSelector(selectIsDiscord);
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const [initialSearchTerm, setInitialSearchTerm] = useState(searchTerm);
  const [resetInitialSearchTerm, setResetInitialSearchTerm] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const hintText = isInputFocused
    ? ''
    : getLocalizedText(getSearchbarPlaceholder(breakpoint, isDiscord));

  useEffect(() => {
    if (resetInitialSearchTerm) {
      setResetInitialSearchTerm(false);
      setInitialSearchTerm(searchTerm);
    }
  }, [resetInitialSearchTerm, searchTerm]);

  const onSuggestionClick = useCallback(
    (searchTermNew) => {
      // An edge case: If the user edits the selected suggestion term and then selects it again, then the input value is not updated.
      if (searchTermNew === initialSearchTerm) {
        setInitialSearchTerm('');
        setResetInitialSearchTerm(true);
      } else {
        setInitialSearchTerm(searchTermNew);
      }
    },
    [initialSearchTerm, setInitialSearchTerm],
  );

  return (
    <>
      <div
        id="searchBarContainer"
        data-testid="searchBarContainer"
        className={css.searchBarContainer}
      >
        <Searchbar
          autoFocus={!isDiscord}
          hintText={hintText}
          searchBarClassName={css.searchBarWrapperClass}
          hintStyle={hintStyle}
          initialValue={initialSearchTerm}
          iconContainerClassName={css.searchIconContainer}
          onChange={onChange}
          iconProps={isDiscord ? discordIconProps : iconProps}
          onSubmit={onSubmit}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
      </div>
      <SearchSuggestions onClick={onSuggestionClick} />
      {!hasSearchSuggestions && (
        <div
          id="searchResultsContainer"
          data-testid="searchResultsContainer"
          data-testvalue={searchTerm}
          className={css.containerItems}
        >
          <RecentSearches searchTerm={searchTerm}>{children}</RecentSearches>
        </div>
      )}
    </>
  );
}

SearchContent.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};
