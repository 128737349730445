import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { logFeatureActivity } from '../../actions/logging';
import { addRecentSearch } from '../../actions/search';
import feature from '../../constants/analytics/categoryActionLabel/feature';
import useActions from '../../hooks/useActions';
import { selectSearchSuggestions } from '../../selectors/search';
import Suggestion from './Suggestion';
import css from './recent-searches.module.scss';
import searchCss from './search.module.scss';

export default function SearchSuggestions({ onClick }) {
  const { suggestions, title } = useSelector(selectSearchSuggestions);
  const actions = useActions({
    addRecentSearch,
    logFeatureActivity,
  });

  if (!suggestions?.length) {
    return null;
  }

  const onSuggestionClick = (searchTerm) => {
    if (searchTerm) {
      onClick(searchTerm);
      actions.addRecentSearch(searchTerm);
      actions.logFeatureActivity(
        feature.actions.searchSuggestion,
        feature.labels.click,
      );
    }
  };

  return (
    <div
      data-testid="search-suggestions-container"
      className={classNames(
        searchCss.containerItems,
        css.recentSearchesContainer,
      )}
    >
      <div className={css.recentSearchesTitleContainer}>
        <h1
          data-testid="search-suggestions-title"
          className={css.recentSearchesTitle}
        >
          {title}
        </h1>
      </div>
      {suggestions?.map((suggestion) => (
        <Suggestion
          key={`search-suggestion-${suggestion?.index}`}
          suggestion={suggestion}
          onClick={onSuggestionClick}
        />
      ))}
    </div>
  );
}

SearchSuggestions.propTypes = {
  onClick: PropTypes.func.isRequired,
};
